

















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import { PPError } from '@/ppapi/PPError'
import Modal from '@/components/Modal.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import {
  phoneNumberMask,
  usPhoneNumber,
  usPhoneNumberDigits,
} from '@/util/validators/phoneNumberValidator'
import tel from '@/util/phoneNumber'

@Component({
  components: { Modal, AlertMessage, TheMask },
  mixins: [validationMixin],
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
  validations: {
    phoneNumber: {
      required,
      usPhoneNumber,
    },
  },
})
export default class EditSms extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  @Prop({ type: String, required: true })
  modalId!: string

  @Prop({ type: String, default: '' })
  currentNumber?: string

  @Prop({ type: Boolean, default: false })
  isEnabled?: boolean

  checked: boolean = !!this.isEnabled
  phoneNumber: string = this.currentNumber || ''

  get mask(): string {
    return phoneNumberMask(this.phoneNumber)
  }

  get classList(): object {
    // add bootstrap classes
    const classList = ['form-control']
    if (this.$v.$dirty) {
      classList.push(this.$v.$invalid ? 'is-invalid' : 'is-valid')
    }
    return classList
  }

  get invalidFeedbackMessage(): TranslateResult | null {
    if (this.$v.phoneNumber) {
      if (!this.$v.phoneNumber.required) {
        return this.$t('_validations.required', { field: 'phone number' })
      }
      if (!this.$v.phoneNumber.usPhoneNumber) {
        return this.$t('_validations.phoneNumber')
      }
    }
    return null
  }

  get submitDisabled() {
    if (this.checked) {
      return this.$v.$invalid || this.isInProgress('user/updateSms')
    } else {
      return this.isInProgress('user/updateSms')
    }
  }

  onShown() {
    const mask = this.$refs.inputMask as Vue
    if (mask && this.checked) {
      const input = mask.$el as HTMLInputElement
      input.focus()
    }
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.checked = !!this.isEnabled
    this.phoneNumber = this.currentNumber || ''
    this.$store.commit('asyncStatus/reset', { key: 'user/updateSms' })
    this.$v.$reset()
  }

  async updateSetting(): Promise<void> {
    if (this.checked) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const phoneDigits = usPhoneNumberDigits(this.phoneNumber)
      await this.$store.dispatch('user/updateSms', { enable: true, phoneNumber: phoneDigits })
    } else {
      await this.$store.dispatch('user/updateSms', { enable: false })
    }

    if (this.hasSucceeded('user/updateSms')) {
      let message
      if (this.checked) {
        message = this.$t('You will now receive text messages at "{number}"', {
          number: tel(this.phoneNumber),
        })
      } else {
        message = this.$t('You will no longer receive text messages from PillPack')
      }
      this.$bvToast.toast(message as string, {
        title: this.$t('Text preferences updated') as string,
        variant: 'success',
      })
      this.close()
    }
  }
}
