





































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { PPError } from '@/ppapi/PPError'
import Modal from '@/components/Modal.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import User from '@/models/User'

@Component({
  components: { Modal, AlertMessage },
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
    ...mapState('user', ['me']),
  },
})
export default class EditPhiInCommunicationsModal extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  @Prop({ type: String, required: true })
  modalId!: string

  me!: User
  phiInCommunications: boolean = false

  onShow() {
    this.phiInCommunications = !!(
      this.me.preferences && this.me.preferences.phiInCommunicationsEnabled
    )
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.$store.commit('asyncStatus/reset', { key: 'user/updatePhiInCommunications' })
  }

  async updateSetting() {
    const turningOn = this.phiInCommunications

    await this.$store.dispatch('user/updatePhiInCommunications', {
      enable: turningOn,
    })

    if (this.hasSucceeded('user/updatePhiInCommunications')) {
      const msg = turningOn
        ? this.$t('Emails may now include medication names')
        : this.$t('Emails will no longer include medication names')
      this.$bvToast.toast(msg as string, {
        title: this.$t('Preference changed') as string,
        variant: 'success',
      })
      this.close()
    }
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }
}
