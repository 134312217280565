
































































































import { Component, Vue } from 'vue-property-decorator'

import { mapState, mapGetters } from 'vuex'
import PageHeader from '@/components/PageHeader.vue'
import ModalSettingWrapper from '@/components/settings/ModalSettingWrapper.vue'
import EditEmailModal from '@/components/settings/EditEmailModal.vue'
import EditPhiInCommunicationsModal from '@/components/settings/EditPhiInCommunicationsModal.vue'
import EditPhoneNumberModal from '@/components/settings/EditPhoneNumberModal.vue'
import EditNameModal from '@/components/settings/EditNameModal.vue'
import EditSmsModal from '@/components/settings/EditSmsModal.vue'
import HelpSearchCard from '@/components/HelpSearchCard.vue'
import SkeletonLine from '@/components/SkeletonLine.vue'
import Card from '@/components/Card.vue'
import User from '@/models/User'

@Component({
  components: {
    Card,
    PageHeader,
    ModalSettingWrapper,
    EditEmailModal,
    EditPhiInCommunicationsModal,
    EditPhoneNumberModal,
    EditNameModal,
    EditSmsModal,
    HelpSearchCard,
    SkeletonLine,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
  },
})
export default class ContactPreferencesView extends Vue {
  isInProgress!: (key: string) => boolean
  getError!: (key: string) => Error
  me?: User

  get loadingUser() {
    return this.isInProgress('user/loadMe')
  }

  get hasError() {
    return !!this.getError('user/loadMe')
  }

  get smsPreferences() {
    if (!this.me) {
      return {}
    }

    const isEnabled = !!(this.me.preferences && this.me.preferences.smsEnabled)
    const phoneNumber = (this.me.smsPhoneNumber && this.me.smsPhoneNumber.number) || ''

    return { isEnabled, phoneNumber }
  }

  get phiInCommunications(): boolean {
    return !!(this.me && this.me.preferences && this.me.preferences.phiInCommunicationsEnabled)
  }

  get breadcrumbs() {
    return [
      {
        text: this.$t('pages.settings.title'),
        to: { name: 'settings' },
      },
      {
        text: this.$t('pages.settings.contact.title'),
        to: { name: 'contact-preferences' },
      },
    ]
  }
}
