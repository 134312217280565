














































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { PPError } from '@/ppapi/PPError'
import { usPhoneNumber, usPhoneNumberDigits } from '@/util/validators/phoneNumberValidator'
import { validationStateMixin } from '@/util/validationState'
import Modal from '@/components/Modal.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import AlertMessage from '@/components/AlertMessage.vue'

@Component({
  components: { Modal, AlertMessage, PhoneInput },
  mixins: [validationMixin, validationStateMixin],
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
  validations: {
    phoneNumber: {
      required,
      usPhoneNumber,
    },
  },
})
export default class EditPhoneNumber extends Vue {
  $refs!: {
    phoneInput: PhoneInput
  }

  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  @Prop({ type: String, required: true })
  modalId!: string

  @Prop(String)
  currentNumber?: string

  phoneNumber: string = this.currentNumber || ''

  get invalidFeedbackMessage(): TranslateResult | null {
    if (this.$v.phoneNumber) {
      if (!this.$v.phoneNumber.required) {
        return this.$t('_validations.required', { field: 'phone number' })
      }
      if (!this.$v.phoneNumber.usPhoneNumber) {
        return this.$t('_validations.phoneNumber')
      }
    }
    return null
  }

  onShown() {
    this.$refs.phoneInput.focus()
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.phoneNumber = this.currentNumber || ''
    this.$store.commit('asyncStatus/reset', { key: 'user/updatePhoneNumber' })
    this.$v.$reset()
  }

  async updateSetting(): Promise<void> {
    this.$v.$touch()
    if (this.$v.$invalid) {
      return
    }
    const phoneDigits = usPhoneNumberDigits(this.phoneNumber)
    await this.$store.dispatch('user/updatePhoneNumber', phoneDigits)

    if (this.hasSucceeded('user/updatePhoneNumber')) {
      this.$bvToast.toast(
        this.$t('Your phone number is now "{number}"', {
          number: this.phoneNumber,
        }) as string,
        {
          title: this.$t('Phone number changed') as string,
          variant: 'success',
        },
      )
      this.close()
    }
  }
}
