



















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { PPError } from '@/ppapi/PPError'
import Modal from '@/components/Modal.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import featureFlags from '@/util/featureFlags'

@Component({
  components: { Modal, AlertMessage },
  mixins: [validationMixin],
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
  validations: {
    email: { required, email },
  },
})
export default class EditEmail extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  @Prop({ type: String, required: true })
  modalId!: string

  @Prop(String)
  currentEmail!: string

  email: string = this.currentEmail || ''

  get invalidFeedbackMessage(): TranslateResult | null {
    if (!this.$v.email.required) {
      return this.$t('_validations.required', { field: 'email' })
    } else if (!this.$v.email.email) {
      return this.$t('_validations.email')
    }

    return null
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.email = this.currentEmail || ''
    this.$store.commit('asyncStatus/reset', { key: 'user/updateEmail' })
    this.$v.$reset()
  }

  async updateSetting(): Promise<void> {
    this.$v.$touch()

    if (!this.$v.$invalid) {
      const email = this.email
      await this.$store.dispatch('user/updateEmail', email)

      const requireConfirmation = await featureFlags.enabled('email-reconfirmation')
      const successMessage = requireConfirmation
        ? (this.$t('confirmation link sent', { email }) as string)
        : (this.$t('Your email address is now "{email}"', { email }) as string)
      const successTitle = requireConfirmation
        ? (this.$t('Email confirmation required') as string)
        : (this.$t('Email changed') as string)

      if (this.hasSucceeded('user/updateEmail')) {
        this.$bvToast.toast(successMessage, {
          title: successTitle,
          variant: 'success',
        })
        this.close()
      }
    }
  }
}
