import { render, staticRenderFns } from "./EditEmailModal.vue?vue&type=template&id=a6805c2c&"
import script from "./EditEmailModal.vue?vue&type=script&lang=ts&"
export * from "./EditEmailModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BForm } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
import { BFormInput } from 'bootstrap-vue'
installComponents(component, {BButton,BForm,BFormGroup,BFormInput})
