
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { PPError } from '@/ppapi/PPError'
import Modal from '@/components/Modal.vue'
import AlertMessage from '@/components/AlertMessage.vue'

const MAX_NAME_LENGTH = 70

// TODO all validation, error messages, and screen reader alerts
@Component({
  components: { Modal, AlertMessage },
  mixins: [validationMixin],
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
  validations: {
    name: { required, maxLength: maxLength(MAX_NAME_LENGTH) },
  },
})
export default class EditNameModal extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  @Prop({ type: String, required: true })
  modalId!: string

  @Prop(String)
  currentName?: string

  name: string = this.currentName || ''

  // set this property to the name you want to update: first_name, last_name, preferred_name, etc.
  // the backend will reject all other properties.
  @Prop({
    type: String,
    required: true,
    validator: value => {
      return ['firstName', 'lastName', 'rawPreferredName'].includes(value)
    },
  })
  readonly nameProperty!: string

  get invalidFeedbackMessage(): TranslateResult | null {
    if (this.$v.name && this.$v.name.$dirty) {
      if (!this.$v.name.required) {
        return this.$t('_validations.required', { field: 'name' })
      }

      if (!this.$v.name.maxLength) {
        return this.$t('_validations.maxLength', { max: MAX_NAME_LENGTH })
      }
    }

    return null
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.name = this.currentName || ''
    this.$store.commit('asyncStatus/reset', { key: 'user/updateName' })
    this.$v.$reset()
  }

  async updateSetting(): Promise<void> {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return
    }

    const payload = {
      nameProperty: this.nameProperty,
      name: this.name,
    }

    await this.$store.dispatch('user/updateName', payload)

    if (this.hasSucceeded('user/updateName')) {
      this.$bvToast.toast(
        this.$t('Your preferred name is now "{name}"', { name: this.name }) as string,
        {
          title: this.$t('Name changed') as string,
          variant: 'success',
        },
      )
      this.close()
    }
  }
}
