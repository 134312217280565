import { render, staticRenderFns } from "./ContactPreferencesView.vue?vue&type=template&id=4d59d764&scoped=true&"
import script from "./ContactPreferencesView.vue?vue&type=script&lang=ts&"
export * from "./ContactPreferencesView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d59d764",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import IconSpeakerphone from '@/assets/icons/icon-speakerphone.svg'
installComponents(component, {IconSpeakerphone})
