

















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ModalSettingWrapper extends Vue {
  @Prop({ type: String, required: true })
  label!: string

  @Prop(String)
  value?: string

  @Prop(String)
  editText?: string

  @Prop({ type: String })
  modalId!: string

  @Prop({ type: Boolean, default: false })
  readonly?: boolean
}
